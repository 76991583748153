import { useDispatch, useSelector } from 'react-redux';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import { addTosessionStorage, getFromsessionStorage } from '@utils/sessionStorage';
import { useGetLoggedUserMutation, useGetUserQuery } from '@store/endpoints/user';
import { toggleDeleteUserModal, toggleSigninModal } from '@store/reducers/modal';
import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
import { requestPermission } from '@utils/firebaseInit';
import * as endpoints from '@store/endpoints/auth';
import * as Types from '$types/components/login';
import { setUser } from '@store/reducers/user';
import dev from '@configs/env.config.json';
import { User } from '$types/store/store';
import { RootState } from '@store/store';
import Loader from '@components/loaders';
import styles from './layout.module.scss';
import Advertisement from '@components/modals/Advertisement';
import AccountDelete from '@components/cards/account-delete';
import RestrictStudent from '@components/cards/restrict-student';

const Navbar = lazy(() => import('@shared/left-sidebar/partials/navbar'));
const SignInCard = lazy(() => import('@components/cards/signIn'));
const Modal = lazy(() => import('@components/partials/Modal'));

declare const google: any;

interface UserData {
	result: User;
}

const useScript = (url: string, onload: () => void, user: User | undefined) => {
	useEffect(() => {
		if (!user?.email) {
			const script = document.createElement('script');

			script.src = url;
			script.onload = onload;
			script.async = true;
			script.defer = true;
			script.id = 'google-client-script';

			document.querySelector('body')?.appendChild(script);
		}

		return () => {
			window.google?.accounts.id.cancel();
			document.getElementById('google-client-script')?.remove();
		};
	}, [user, onload]);
};

const Main = () => {
	const showModal = useSelector((state: RootState) => state.modal.signinModal);
	const showDeleteUserModal = useSelector((state: RootState) => state.modal.deleteUserModal);
	const user = useSelector((state: RootState) => state.user.user);
	const dispatch = useDispatch();

	const [isCreatorModalVisible, setIsCreatorModalVisible] = useState(false);
	const [showAdvertisementModal, setAdvertisementModal] = useState(false);
	const [btnLink, setBtnLink] = useState('');

	const [googlesignin] = endpoints.useGoogleSigninMutation();
	const [loggedUser] = useGetLoggedUserMutation();

	const { data: userData } = useGetUserQuery({ email: user?.email }, { skip: !user?.email }) as {
		data: UserData | undefined;
	};

	const setShowModal = () => {
		dispatch(toggleSigninModal());
	};

	const setShowDeleteUserModal = () => {
		dispatch(toggleDeleteUserModal());
	};

	const signGoogleRequestBody = (response: Types.GoogleResponse) => {
		const userObject: any = jwtDecode(response.credential);
		const user = {
			firstName: userObject.given_name,
			lastName: userObject.family_name,
			email: userObject.email,
			password: '',
			googleId: userObject.sub,
			profileImage: userObject.picture,
			isVerified: true,
		};
		const loadingToastId = toast.loading('Signing you in...');
		googlesignin(user)
			.unwrap()
			.then(data => {
				toast.update(loadingToastId, {
					render: 'Successfully Signed in! 👌',
					type: toast.TYPE.SUCCESS,
					isLoading: false,
					autoClose: 3000,
				});
				setShowModal();
				loggedUser(null)
					.unwrap()
					.then(data => {
						requestPermission({ isIos: false });
						dispatch(setUser(data.result[0]));
					})
					.catch(error => {
						console.log({ error });
						window.location.reload();
					});
			})
			.catch(error => {
				toast.update(loadingToastId, {
					render: error.data.error,
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 3000,
				});
			});
	};

	useScript(
		'https://accounts.google.com/gsi/client',
		() => {
			/* global google */

			google.accounts.id.initialize({
				client_id: dev.GOOGLE_CLIENT_ID_OFFICIAL,
				callback: signGoogleRequestBody,
			} as any);

			// google.accounts.id.prompt(); //for one-tap login
		},
		user
	);

	const getOperatingSystem = () => {
		const userAgent = navigator.userAgent;

		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone';
		}

		if (/android/i.test(userAgent)) {
			return 'Android';
		}

		if (/iPad|iPhone|iPod/.test(userAgent)) {
			return 'iOS';
		}

		return 'unknown';
	};

	useEffect(() => {
		if (userData) {
			addToLocalStorage('isCreator', JSON.stringify(userData.result.isCreator));
		}
	}, [userData]);

	const handleWindowClick = () => {
		window.location.reload();
	};

	useEffect(() => {
		const isCreatorFromLocalStorage = getFromLocalStorage('isCreator') || false;
		const isCreatorFromAPI = userData?.result?.isCreator;

		if (isCreatorFromLocalStorage === false && isCreatorFromAPI === false) {
			setIsCreatorModalVisible(true);

			window.addEventListener('click', handleWindowClick);

			return () => {
				window.removeEventListener('click', handleWindowClick);
			};
		}
	}, [userData]);

	useEffect(() => {
		setTimeout(() => {
			if (!getFromsessionStorage('bannerShow')) {
				const system = getOperatingSystem();
				if (system === 'iOS') setBtnLink('https://apps.apple.com/in/app/plutonn/id6470260657');
				else setBtnLink('https://play.google.com/store/apps/details?id=com.plutonn.plutonn');
				setAdvertisementModal(true);
				addTosessionStorage('bannerShow', true);
			}
		}, 60000);
	}, []);

	return (
		<>
			<Suspense fallback={<Loader.PageLoader />}>
				<Outlet />
				<Modal setShowModal={setShowModal} showModal={showModal} className='signinModal'>
					<SignInCard setShowModal={setShowModal} />
				</Modal>
				<Modal setShowModal={setShowDeleteUserModal} showModal={showDeleteUserModal} className='deleteUserModal'>
					<AccountDelete />
				</Modal>
				<nav className={`${styles.nav}`}>
					<Navbar className={styles.navCard} />
				</nav>

				<Modal
					setShowModal={setIsCreatorModalVisible}
					showModal={isCreatorModalVisible}
					disableCloseIcon={true}
					closeOnClickOutside={true}>
					<RestrictStudent />
				</Modal>
			</Suspense>

			<Advertisement
				setShowModal={setAdvertisementModal}
				showModal={showAdvertisementModal}
				title='Switch to Plutonn App! '
				mainText='Faster and seamless performance, exclusive features, and more. Download now from App Store or Google Play!'
				buttonLink={btnLink}
				buttonText='Install Now'
			/>
		</>
	);
};

export default Main;

/* eslint-disable camelcase */
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

import { useGetTrendingCoursesQuery } from '@store/endpoints/courses';
import { trendingCourse } from '$types/components/trendingCourse';
import LazyImage from '@components/shared/lazy-image';
import SkeletonAtom from '@skeletons/SkeletonAtom';
import numberFormat from 'src/utils/numberFormat';
import styles from './trending.module.scss';

export const CSCourse = (course: trendingCourse) => {
	const { _id, cost, title, meta, enrolled_count, thumbnailImage, seoTitle, firstVideoTitle } = course;

	return (
		<li className={styles.cardItem2} key={_id}>
			<Link
				to={`https://www.cipherschools.com/courses/${seoTitle}/${firstVideoTitle}`}
				className={styles.card2}
				target='_blank'>
				<div className={styles.cardImage2}>
					<LazyImage src={thumbnailImage} alt={title} className={styles.cardImage2} />
				</div>
				<div className={styles.cardContent2}>
					<h2 className={styles.cardTitle2}>{title}</h2>
					<h3 className={styles.cardCost2}>{cost ? cost : 'Free'}</h3>
					<h3 className={styles.cardViews2}>
						{numberFormat(meta?.count || enrolled_count)} {meta?.text || 'Enrollments'}
					</h3>
				</div>
			</Link>
		</li>
	);
};

const TrendingCourse = () => {
	const { data, isLoading } = useGetTrendingCoursesQuery();

	return (
		<div className={styles.container2}>
			<div className={styles.cardHeader2}>
				<Icon icon='ph:chart-bar-fill' className={styles.cardIcon2} />
				<h2 className={styles.cardTitle2}>Trending Courses</h2>
			</div>
			{isLoading ? (
				<>
					{Array(6)
						.fill(0)
						.map((e, i) => (
							<SkeletonAtom type='course' key={i} />
						))}
				</>
			) : (
				<ul className={styles.cardList2}>{data?.result.data.map(item => CSCourse(item))}</ul>
			)}
		</div>
	);
};

export default TrendingCourse;
